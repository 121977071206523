import React, { useContext } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { IndexLink } from 'react-router';
import { Flex } from './sharedComponents/layoutHelpers';
import * as colors from './lib/colors';
import { DESKTOP, TABLET } from './lib/constants';
import { ViewportContext } from './viewportContext';
import { AppPathContext } from './appPathContext';

const styles = StyleSheet.create({
  logoContainer: {
    flex: '1 0 300px',
  },
  slogan: {
    marginRight: 'auto',
    fontSize: '0.6875em',
    letterSpacing: '1px',
    lineHeight: '1.45em',
    color: colors.MANATEE_GREY,
    textTransform: 'uppercase',
    marginTop: '4px',
  },
});

export const Logo = () => {
  const viewport = useContext(ViewportContext);
  const appPath = useContext(AppPathContext);

  return (
    <div className={css(styles.logoContainer)}>
      <Flex alignItems={'center'}>
        <IndexLink
          to={appPath}
          className="icon-civiqs-logo"
          style={{
            textDecoration: 'none',
            fontSize: 28,
            color: colors.BLUE,
            marginLeft: 32,
            marginRight: 14,
            marginTop: -9,
          }}
        />
        {viewport === TABLET || viewport === DESKTOP ? (
          <span className={css(styles.slogan)}>Your Turn to Be Heard</span>
        ) : null}
      </Flex>
    </div>
  );
};
