import React, { useContext } from 'react';
import { StyleSheet, css } from 'aphrodite-jss';
import { FlexList } from './sharedComponents/layoutHelpers';
import { SILVER_CHALICE } from './lib/colors';
import { TISA } from './lib/fonts';
import { MOBILE } from './lib/constants';
import { ViewportContext } from './viewportContext';

const styles = StyleSheet.create({
  container: {
    maxWidth: 900,
    paddingRight: '1.5em',
    paddingLeft: '1.5em',
    fontFamily: TISA,
    color: SILVER_CHALICE,
  },
  navList: {
    paddingTop: 48,
    paddingBottom: 30,
    listStyle: 'none',
  },
  navItemContainer: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  navItemLink: {
    color: SILVER_CHALICE,
    textDecoration: 'none',
  },
  blurb: {
    fontSize: '1em',
    marginBottom: '2em',
  },
});

const FooterLink = ({ email, to, children }) => {
  return email ? (
    <li className={css(styles.navItemContainer)}>
      <a href={`mailto:${email}`} className={css(styles.navItemLink)}>
        Contact
      </a>
    </li>
  ) : (
    <li className={css(styles.navItemContainer)}>
      <a href={to} target={'_blank'} style={styles.navItemLink.style}>
        {children}
      </a>
    </li>
  );
};

export const Footer = () => {
  const viewport = useContext(ViewportContext);

  return (
    <div className={css(styles.container)}>
      <FlexList
        flexFlow={viewport === MOBILE ? 'column nowrap' : 'row nowrap'}
        justifyContent="center"
        alignItems="center"
        style={styles.navList}
      >
        <FooterLink to="/survey-terms-of-service">Terms of Use</FooterLink>
        <span>&nbsp;&#8212;&nbsp;</span>
        <FooterLink to="/privacy/">Privacy Policy</FooterLink>
        <span>&nbsp;&#8212;&nbsp;</span>
        <FooterLink to="/services/polling">Contact</FooterLink>
      </FlexList>
      <div className={css(styles.blurb)}>
        Civiqs is an independent opinion research company that helps
        politicians, policymakers, and media organizations understand the views
        of people like you.
      </div>
    </div>
  );
};
